import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3b3ad4f3 = () => interopDefault(import('../pages/catalog.vue' /* webpackChunkName: "pages/catalog" */))
const _79b33d7a = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _60cb3950 = () => interopDefault(import('../pages/feature.vue' /* webpackChunkName: "pages/feature" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5e96a5f4 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _b744154a = () => interopDefault(import('../pages/songs/index.vue' /* webpackChunkName: "pages/songs/index" */))
const _ca9a2e3a = () => interopDefault(import('../pages/themes/index.vue' /* webpackChunkName: "pages/themes/index" */))
const _5cda5e36 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _635768b1 = () => interopDefault(import('../pages/wedding/index.vue' /* webpackChunkName: "pages/wedding/index" */))
const _0f1500c3 = () => interopDefault(import('../pages/songs/create.vue' /* webpackChunkName: "pages/songs/create" */))
const _3943038a = () => interopDefault(import('../pages/themes/create.vue' /* webpackChunkName: "pages/themes/create" */))
const _ef08d30e = () => interopDefault(import('../pages/users/create.vue' /* webpackChunkName: "pages/users/create" */))
const _2f49f82d = () => interopDefault(import('../pages/wedding/create.vue' /* webpackChunkName: "pages/wedding/create" */))
const _8be0dafa = () => interopDefault(import('../pages/songs/_id.vue' /* webpackChunkName: "pages/songs/_id" */))
const _bac86fea = () => interopDefault(import('../pages/themes/_id.vue' /* webpackChunkName: "pages/themes/_id" */))
const _21e1678e = () => interopDefault(import('../pages/wedding/_wedding_code/index.vue' /* webpackChunkName: "pages/wedding/_wedding_code/index" */))
const _2cd1c7fc = () => interopDefault(import('../pages/users/_id/edit.vue' /* webpackChunkName: "pages/users/_id/edit" */))
const _2b7f4382 = () => interopDefault(import('../pages/wedding/_wedding_code/guest.vue' /* webpackChunkName: "pages/wedding/_wedding_code/guest" */))
const _3f686ec0 = () => interopDefault(import('../pages/wedding/_wedding_code/guest-mobile.vue' /* webpackChunkName: "pages/wedding/_wedding_code/guest-mobile" */))
const _b3bccfe0 = () => interopDefault(import('../pages/wedding/_wedding_code/wish.vue' /* webpackChunkName: "pages/wedding/_wedding_code/wish" */))
const _b0891c18 = () => interopDefault(import('../pages/_wedding_code/index.vue' /* webpackChunkName: "pages/_wedding_code/index" */))
const _c6500fc2 = () => interopDefault(import('../pages/_wedding_code/original.vue' /* webpackChunkName: "pages/_wedding_code/original" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/catalog",
    component: _3b3ad4f3,
    name: "catalog___en"
  }, {
    path: "/contact",
    component: _79b33d7a,
    name: "contact___en"
  }, {
    path: "/feature",
    component: _60cb3950,
    name: "feature___en"
  }, {
    path: "/id",
    component: _98173ca8,
    name: "index___id"
  }, {
    path: "/login",
    component: _5e96a5f4,
    name: "login___en"
  }, {
    path: "/songs",
    component: _b744154a,
    name: "songs___en"
  }, {
    path: "/themes",
    component: _ca9a2e3a,
    name: "themes___en"
  }, {
    path: "/users",
    component: _5cda5e36,
    name: "users___en"
  }, {
    path: "/wedding",
    component: _635768b1,
    name: "wedding___en"
  }, {
    path: "/id/catalog",
    component: _3b3ad4f3,
    name: "catalog___id"
  }, {
    path: "/id/contact",
    component: _79b33d7a,
    name: "contact___id"
  }, {
    path: "/id/feature",
    component: _60cb3950,
    name: "feature___id"
  }, {
    path: "/id/login",
    component: _5e96a5f4,
    name: "login___id"
  }, {
    path: "/id/songs",
    component: _b744154a,
    name: "songs___id"
  }, {
    path: "/id/themes",
    component: _ca9a2e3a,
    name: "themes___id"
  }, {
    path: "/id/users",
    component: _5cda5e36,
    name: "users___id"
  }, {
    path: "/id/wedding",
    component: _635768b1,
    name: "wedding___id"
  }, {
    path: "/songs/create",
    component: _0f1500c3,
    name: "songs-create___en"
  }, {
    path: "/themes/create",
    component: _3943038a,
    name: "themes-create___en"
  }, {
    path: "/users/create",
    component: _ef08d30e,
    name: "users-create___en"
  }, {
    path: "/wedding/create",
    component: _2f49f82d,
    name: "wedding-create___en"
  }, {
    path: "/id/songs/create",
    component: _0f1500c3,
    name: "songs-create___id"
  }, {
    path: "/id/themes/create",
    component: _3943038a,
    name: "themes-create___id"
  }, {
    path: "/id/users/create",
    component: _ef08d30e,
    name: "users-create___id"
  }, {
    path: "/id/wedding/create",
    component: _2f49f82d,
    name: "wedding-create___id"
  }, {
    path: "/id/songs/:id",
    component: _8be0dafa,
    name: "songs-id___id"
  }, {
    path: "/id/themes/:id",
    component: _bac86fea,
    name: "themes-id___id"
  }, {
    path: "/id/wedding/:wedding_code",
    component: _21e1678e,
    name: "wedding-wedding_code___id"
  }, {
    path: "/id/users/:id/edit",
    component: _2cd1c7fc,
    name: "users-id-edit___id"
  }, {
    path: "/id/wedding/:wedding_code/guest",
    component: _2b7f4382,
    name: "wedding-wedding_code-guest___id"
  }, {
    path: "/id/wedding/:wedding_code/guest-mobile",
    component: _3f686ec0,
    name: "wedding-wedding_code-guest-mobile___id"
  }, {
    path: "/id/wedding/:wedding_code/wish",
    component: _b3bccfe0,
    name: "wedding-wedding_code-wish___id"
  }, {
    path: "/id/:wedding_code",
    component: _b0891c18,
    name: "wedding_code___id"
  }, {
    path: "/songs/:id",
    component: _8be0dafa,
    name: "songs-id___en"
  }, {
    path: "/themes/:id",
    component: _bac86fea,
    name: "themes-id___en"
  }, {
    path: "/wedding/:wedding_code",
    component: _21e1678e,
    name: "wedding-wedding_code___en"
  }, {
    path: "/id/:wedding_code/original",
    component: _c6500fc2,
    name: "wedding_code-original___id"
  }, {
    path: "/users/:id/edit",
    component: _2cd1c7fc,
    name: "users-id-edit___en"
  }, {
    path: "/wedding/:wedding_code/guest",
    component: _2b7f4382,
    name: "wedding-wedding_code-guest___en"
  }, {
    path: "/wedding/:wedding_code/guest-mobile",
    component: _3f686ec0,
    name: "wedding-wedding_code-guest-mobile___en"
  }, {
    path: "/wedding/:wedding_code/wish",
    component: _b3bccfe0,
    name: "wedding-wedding_code-wish___en"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___en"
  }, {
    path: "/:wedding_code",
    component: _b0891c18,
    name: "wedding_code___en"
  }, {
    path: "/:wedding_code/original",
    component: _c6500fc2,
    name: "wedding_code-original___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
